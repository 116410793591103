export default {
  "首页": "Trang chủ",
  "订单": "Đơn hàng",
  "新闻": "Tin tức",
  "个人中心": "Trung tâm cá nhân",
  "模拟账户": "Tài khoản demo",
  "可用资产": "Tài sản khả dụng",
  "入金": "Nạp tiền",
  "出金": "Rút tiền",
  "账单详情": "Chi tiết hóa đơn",
  "重置模拟账户": "Đặt lại tài khoản demo",
  "钱包": "Ví",
  "实名认证": "Xác thực danh tính",
  "邀请好友": "Mời bạn bè",
  "修改密码": "Đổi mật khẩu",
  "投诉邮箱": "Email khiếu nại",
  "其它": "Khác",
  "公告": "Thông báo",
  "语言": "Ngôn ngữ",
  "是否退出登录": "Bạn có chắc chắn muốn đăng xuất?",
  "复制": "Sao chép",
  "复制成功": "Sao chép thành công",
  "发送验证码成功": "Gửi mã xác nhận thành công",
  "请输入密码": "Vui lòng nhập mật khẩu",
  "请再次输入密码": "Vui lòng nhập lại mật khẩu",
  "复制邀请链接": "Sao chép liên kết mời",
  "上传身份": "Vui lòng tải lên mặt trước và mặt sau của hộ chiếu/giấy tờ tùy thân",
  "正面": "Mặt trước",
  "上传正面": "Tải lên mặt trước của hộ chiếu/giấy tờ tùy thân",
  "反面": "Mặt sau",
  "上传反面": "Tải lên mặt sau của hộ chiếu/giấy tờ tùy thân",
  "完善信息": "Vui lòng hoàn thiện thông tin cá nhân",
  "输入姓名": "Vui lòng nhập họ và tên",
  "身份证号": "Số hộ chiếu/gấy tờ tùy thân",
  "输入身份证号": "Vui lòng nhập số hộ chiếu/gấy tờ tùy thân",
  "收款货币": "Tiền tệ nhận",
  "银行名称": "Tên ngân hàng",
  "收款人账户": "Tài khoản người nhận",
  "货币": "Tiền tệ",
  "分店名称": "Tên chi nhánh",
  "绑定银行卡": "Liên kết thẻ ngân hàng",
  "银行地址": "Địa chỉ ngân hàng",
  "账号名称（假名）": "Tên tài khoản (biệt danh)",
  "绑定数字货币地址": "Liên kết địa chỉ tiền kỹ thuật số",
  "余额": "Số dư",
  "联系客服": "Liên hệ với hỗ trợ khách hàng",
  "行情": "Thị trường",
  "其他": "Khác",
  "设置": "Cài đặt",
  "收款人": "Người nhận",
  "添加": "Thêm",
  "确认": "Xác nhận",
  "取消": "Hủy",
  "钱包地址": "Địa chỉ ví",
  "请输入": "Vui lòng nhập",
  "退出登录": "Đăng xuất",
  "充值数量": "Số tiền nạp",
  "提交": "Gửi",
  "银行卡": "Thẻ ngân hàng",
  "选择网络": "Chọn mạng",
  "数字货币": "Tiền kỹ thuật số",
  "上传验证": "Tải lên xác minh",
  "提币地址": "Địa chỉ rút tiền",
  "数量(USD)": "Số lượng (USD)",
  "备注": "Ghi chú",
  "手续费": "Phí giao dịch",
  "预计到账数量": "Số tiền dự kiến nhận",
  "暂无数据": "Không có dữ liệu",
  "入金记录": "Lịch sử nạp tiền",
  "提币记录": "Lịch sử rút tiền",
  "验证码": "Mã xác nhận",
  "邀请码": "Mã mời",
  "提示": "Lời nhắc",
  "请输入验证码": "Vui lòng nhập mã xác nhận",
  "姓名": "Họ tên",
  "邮箱登录": "Đăng nhập bằng email",
  "邮箱": "Email",
  "密码": "Mật khẩu",
  "新用户": "Người dùng mới",
  "立即加入": "Tham gia ngay",
  "忘记密码": "Quên mật khẩu?",
  "登录": "Đăng nhập",
  "无需开户,立即创建模拟账号进行交易!": "Không cần mở tài khoản, ngay lập tức tạo tài khoản demo để giao dịch!",
  "电话": "Số điện thoại",
  "再次输入密码": "Nhập lại mật khẩu",
  "职业": "Nghề nghiệp",
  "年收入": "Thu nhập hàng năm",
  "注册": "Đăng ký",
  "邮箱注册": "Đăng ký bằng email",
  "发送": "Gửi",
  "重置密码": "Đặt lại mật khẩu",
  "是否重置": "Bạn có chắc chắn muốn đặt lại mật khẩu?",
  "已重置": "Mật khẩu đã được đặt lại",
  "名称": "Tên",
  "注册成功": "Đăng ký thành công",
  "加载中": "Đang tải",
  "您还未登录，请先登录": "Bạn chưa đăng nhập, vui lòng đăng nhập trước",
  "退出成功": "Đăng xuất thành công",
  "市价": "Giá thị trường",
  "挂单": "Lệnh chờ",
  "倍数": "Hệ số",
  "止盈": "Chốt lời",
  "止损": "Cắt lỗ",
  "买入数量": "Số lượng mua",
  "每手": "Mỗi hợp đồng",
  "预估手续费": "Phí giao dịch dự kiến",
  "预估保证金": "Ký quỹ dự kiến",
  "买入": "Mua",
  "卖出": "Bán",
  "您的订单已确认": "Đơn hàng của bạn đã được xác nhận",
  "订单页面": "Trang đơn hàng",
  "盈亏": "Lãi/Lỗ",
  "当前保证金": "Ký quỹ hiện tại",
  "订单详情": "Chi tiết đơn hàng",
  "保证金": "Ký quỹ",
  "平仓": "Đóng vị thế",
  "修改": "Chỉnh sửa",
  "手数": "Số hợp đồng",
  "暂无数据": "Không có dữ liệu",
  "历史": "Lịch sử",
  "价格": "Giá",
  "请输入价格": "Vui lòng nhập giá",
  "手": "Hợp đồng",
  "搜索结果": "Kết quả tìm kiếm",
  "搜索产品": "Tìm sản phẩm",
  "持仓": "Vị thế mở",
  "语言": "Ngôn ngữ",
  "审核中": "Đang kiểm tra",
  "请上传证件照正/反面": "Vui lòng tải lên ảnh mặt trước và mặt sau của giấy tờ tùy thân",
  "上传成功": "Tải lên thành công",
  "上传失败": "Tải lên thất bại",
  "提交成功": "Gửi thành công",
  "删除": "Xóa",
  "删除成功": "Xóa thành công",
  "提币": "Rút tiền",
  "缺少参数或传值错误": "Thiếu tham số hoặc giá trị không hợp lệ",
  "操作成功": "Thao tác thành công",
  "充值金额": "Số tiền nạp",
  "类型": "Loại",
  "状态": "Trạng thái",
  "时间": "Thời gian",
  "单位": "Đơn vị",
  "最高价": "Giá cao nhất",
  "最低价": "Giá thấp nhất",
  "开盘价": "Giá mở cửa",
  "请注册或登录真实账号": "Vui lòng đăng ký hoặc đăng nhập vào tài khoản thật",
  "没有更多了": "Không còn dữ liệu nữa",
  "提现金额": "Số tiền rút",
  "审核通过": "Duyệt thành công",
  "审核不通过": "Duyệt không thành công",
  "重新提交": "Nộp lại",
  "止盈价": "Giá chốt lời",
  "止损价": "Giá cắt lỗ",
  "总盈亏": "Lãi/Lỗ tổng cộng",
  "撤单": "Hủy lệnh"
}
