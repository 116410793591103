import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import i18n from './lang'
import Vant, { Toast } from 'vant'
import Barrage from 'vue-barrage'
import vueEsign from 'vue-esign';
import socketApi from './utils/socket'//引入socket.js文件
import 'lib-flexible'
Vue.prototype.socketApi = socketApi //设为全局对象
import api from './api/index.js'
import setHtmlFontSize from './utils/rem'
import cal from './utils/calculate'
Vue.config.productionTip = false

import 'vant/lib/index.css';
import './css/app.scss';

window.global = {
  // baseUrl: 'https://api.loe.xt528.com',
  baseUrl: 'https://api.loetrade.cc',
  // baseWsUrl: 'wss://api.loe.xt528.com/ws'
  baseWsUrl: 'wss://api.loetrade.cc/ws'
}

setHtmlFontSize()
Vue.prototype.cal = cal
// UI settings
Toast.setDefaultOptions({ forbidClick: true })
Vue.use(Vant)

Vue.use(Barrage)

Vue.use(vueEsign)
Vue.prototype.$api = api



new Vue({
  router,
  store,
  i18n,
  components: { App },
  template: '<App/>',
  render: h => h(App)
}).$mount('#app')
