function setHtmlFontSize() {
  const htmlDom = document.getElementsByTagName('html')[0]
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
  if (htmlWidth >= 1920) {
    htmlWidth = 1920
  }
  if (htmlWidth <= 1200) {
    htmlWidth = 1200
  }
  htmlDom.style.fontSize = `${htmlWidth / 19.2}px`
}
window.onresize = setHtmlFontSize
export default setHtmlFontSize
