export default {
  "首页": "मुख्य पृष्ठ",
  "订单": "आदेश",
  "新闻": "समाचार",
  "个人中心": "व्यक्तिगत केंद्र",
  "模拟账户": "डेमो खाता",
  "可用资产": "उपलब्ध संपत्ति",
  "入金": "जमा",
  "出金": "निकासी",
  "账单详情": "विवरण बिल",
  "重置模拟账户": "डेमो खाता रीसेट करें",
  "钱包": "वॉलेट",
  "实名认证": "पहचान सत्यापन",
  "邀请好友": "मित्रों को आमंत्रित करें",
  "修改密码": "पासवर्ड बदलें",
  "投诉邮箱": "शिकायत ईमेल",
  "其它": "अन्य",
  "公告": "घोषणा",
  "语言": "भाषा",
  "是否退出登录": "क्या आप लॉगआउट करना चाहते हैं?",
  "复制": "कॉपी करें",
  "复制成功": "सफलतापूर्वक कॉपी किया गया",
  "发送验证码成功": "सत्यापन कोड भेजा गया",
  "请输入密码": "कृपया पासवर्ड दर्ज करें",
  "请再次输入密码": "कृपया पासवर्ड दोबारा दर्ज करें",
  "复制邀请链接": "आमंत्रण लिंक कॉपी करें",
  "上传身份": "कृपया पासपोर्ट/पहचान पत्र का आगे और पीछे का हिस्सा अपलोड करें",
  "正面": "सामना",
  "上传正面": "सामना अपलोड करें",
  "反面": "पीछे का हिस्सा",
  "上传反面": "पीछे का हिस्सा अपलोड करें",
  "完善信息": "कृपया व्यक्तिगत जानकारी पूरी करें",
  "输入姓名": "कृपया अपना नाम दर्ज करें",
  "身份证号": "पासपोर्ट/पहचान पत्र संख्या",
  "输入身份证号": "कृपया अपनी पहचान संख्या दर्ज करें",
  "收款货币": "प्राप्त मुद्रा",
  "银行名称": "बैंक का नाम",
  "收款人账户": "प्राप्तकर्ता खाता",
  "货币": "मुद्रा",
  "分店名称": "शाखा का नाम",
  "绑定银行卡": "बैंक कार्ड जोड़ें",
  "银行地址": "बैंक का पता",
  "账号名称（假名）": "खाता नाम (काल्पनिक नाम)",
  "绑定数字货币地址": "क्रिप्टो पता जोड़ें",
  "余额": "शेष राशि",
  "联系客服": "ग्राहक सेवा से संपर्क करें",
  "行情": "बाजार",
  "其他": "अन्य",
  "设置": "सेटिंग्स",
  "收款人": "प्राप्तकर्ता",
  "添加": "जोड़ें",
  "确认": "पुष्टि करें",
  "取消": "रद्द करें",
  "钱包地址": "वॉलेट पता",
  "请输入": "कृपया दर्ज करें",
  "退出登录": "लॉगआउट",
  "充值数量": "जमा राशि",
  "提交": "प्रस्तुत करें",
  "银行卡": "बैंक कार्ड",
  "选择网络": "नेटवर्क चुनें",
  "数字货币": "क्रिप्टो",
  "上传验证": "सत्यापन अपलोड करें",
  "提币地址": "निकासी पता",
  "数量(USD)": "मात्रा (USD)",
  "备注": "टिप्पणी",
  "手续费": "शुल्क",
  "预计到账数量": "अनुमानित प्राप्त राशि",
  "暂无数据": "कोई डेटा नहीं",
  "入金记录": "जमा रिकॉर्ड",
  "提币记录": "निकासी रिकॉर्ड",
  "验证码": "सत्यापन कोड",
  "邀请码": "आमंत्रण कोड",
  "提示": "संकेत",
  "请输入验证码": "कृपया सत्यापन कोड दर्ज करें",
  "姓名": "नाम",
  "邮箱登录": "ईमेल लॉगिन",
  "邮箱": "ईमेल",
  "密码": "पासवर्ड",
  "新用户": "नया उपयोगकर्ता",
  "立即加入": "अभी शामिल हों",
  "忘记密码": "पासवर्ड भूल गए",
  "登录": "लॉगिन",
  "无需开户,立即创建模拟账号进行交易!": "कोई खाता आवश्यक नहीं, ट्रेडिंग के लिए डेमो खाता बनाएं!",
  "电话": "फोन",
  "再次输入密码": "पासवर्ड दोबारा दर्ज करें",
  "职业": "व्यवसाय",
  "年收入": "वार्षिक आय",
  "注册": "रजिस्टर करें",
  "邮箱注册": "ईमेल पंजीकरण",
  "发送": "भेजें",
  "重置密码": "पासवर्ड रीसेट करें",
  "是否重置": "क्या रीसेट करना है?",
  "已重置": "रीसेट कर दिया गया है",
  "名称": "नाम",
  "注册成功": "पंजीकरण सफल",
  "加载中": "लोड हो रहा है",
  "您还未登录，请先登录": "आप अभी लॉगिन नहीं हैं, कृपया पहले लॉगिन करें",
  "退出成功": "सफलतापूर्वक लॉगआउट",
  "市价": "बाजार मूल्य",
  "挂单": "लिमिट ऑर्डर",
  "倍数": "गुणक",
  "止盈": "लाभ रोकें",
  "止损": "नुकसान रोकें",
  "买入数量": "खरीद मात्रा",
  "每手": "प्रति लॉट",
  "预估手续费": "अनुमानित शुल्क",
  "预估保证金": "अनुमानित मार्जिन",
  "买入": "खरीदें",
  "卖出": "बेचें",
  "您的订单已确认": "आपका आदेश पुष्टि हो गया है",
  "订单页面": "आदेश पृष्ठ",
  "盈亏": "लाभ/हानि",
  "当前保证金": "वर्तमान मार्जिन",
  "订单详情": "आदेश विवरण",
  "保证金": "मार्जिन",
  "平仓": "स्थिति बंद करें",
  "修改": "संशोधित करें",
  "手数": "लॉट साइज",
  "暂无数据": "कोई डेटा नहीं",
  "历史": "इतिहास",
  "价格": "मूल्य",
  "请输入价格": "कृपया मूल्य दर्ज करें",
  "手": "लॉट",
  "搜索结果": "खोज परिणाम",
  "搜索产品": "उत्पाद खोजें",
  "持仓": "स्थिति",
  "语言": "भाषा",
  "审核中": "समीक्षा में",
  "请上传证件照正/反面": "कृपया पहचान दस्तावेज़ का आगे और पीछे का हिस्सा अपलोड करें",
  "上传成功": "सफलतापूर्वक अपलोड किया गया",
  "上传失败": "अपलोड विफल",
  "提交成功": "प्रस्तुतिकरण सफल",
  "删除": "हटाएं",
  "删除成功": "सफलतापूर्वक हटाया गया",
  "提币": "निकासी",
  "缺少参数或传值错误": "पैरामीटर गायब या मान त्रुटि",
  "操作成功": "सफलतापूर्वक संचालन",
  "充值金额": "जमा राशि",
  "类型": "प्रकार",
  "状态": "स्थिति",
  "时间": "समय",
  "单位": "इकाई",
  "最高价": "उच्चतम मूल्य",
  "最低价": "न्यूनतम मूल्य",
  "开盘价": "खुलने का मूल्य",
  "请注册或登录真实账号": "कृपया एक वास्तविक खाता पंजीकृत करें या लॉगिन करें",
  "没有更多了": "कोई और नहीं",
  "提现金额": "निकासी राशि",
  "审核通过": "स्वीकृत",
  "审核不通过": "अस्वीकृत",
  "重新提交": "पुनः प्रस्तुत करें",
  "止盈价": "लाभ रोकने का मूल्य",
  "止损价": "नुकसान रोकने का मूल्य",
  "总盈亏": "कुल लाभ/हानि",
  "撤单": "आदेश रद्द करें"
}
