const TokenKey = 'trx_token'
const EnumsKey = 'trx_enums'
const MemberKey = 'trx_member'
const LanguageKey = 'trx_language'
const pushStatusKey = 'trx_push_status'
const noPushStatusKey = 'trx_nopush'
const BalanceDisplayKey = 'trx_BalanceDisplay'
const commonData = 'commonData'

// Handle token
export function getToken() {
  return window.localStorage.getItem(TokenKey)
}

export function setToken(data) {
  return window.localStorage.setItem(TokenKey, data)
}

export function removeToken() {
  return window.localStorage.removeItem(TokenKey)
}

// Handle member
export function getMember() {
  var data = window.localStorage.getItem(MemberKey)
  return data ? JSON.parse(data) : {}
}

export function setMember(data) {
  return window.localStorage.setItem(MemberKey, data)
}

export function removeMember() {
  return window.localStorage.removeItem(MemberKey)
}

// Handle enums
export function getEnums() {
  var data = window.localStorage.getItem(EnumsKey)
  return data ? JSON.parse(data) : null
}

export function setEnums(data) {
  return window.localStorage.setItem(EnumsKey, data)
}

export function removeEnums() {
  return window.localStorage.removeItem(EnumsKey)
}

// Handle language
export function getLanguage() {
  return window.localStorage.getItem(LanguageKey) || 'zh-hk'
}

export function setLanguage(data) {
  return window.localStorage.setItem(LanguageKey, data)
}

export function removeLanguage() {
  return window.localStorage.removeItem(LanguageKey)
}

// Handle push status
export function getPushStatus() {
  return window.localStorage.getItem(pushStatusKey)
}

export function setPushStatus(data) {
  return window.localStorage.setItem(pushStatusKey, data)
}

export function removePushStatus() {
  return window.localStorage.removeItem(pushStatusKey)
}

// Handle no push status
export function getNoPushAccount() {
  return window.localStorage.getItem(noPushStatusKey)
}

export function setNoPushAccount(data) {
  return window.localStorage.setItem(noPushStatusKey, data)
}

// 余额显示隐藏
export function getBalanceDisplay() {
  return window.localStorage.getItem(BalanceDisplayKey)
}

export function setBalanceDisplay(data) {
  return window.localStorage.setItem(BalanceDisplayKey, data)
}

// 公共信息
export function getCommonData() {
  return JSON.parse(window.localStorage.getItem(commonData))
}

export function setCommonData(data) {
  return window.localStorage.setItem(commonData, JSON.stringify(data))
}
