export default {
  "首页": "หน้าแรก",
  "订单": "คำสั่ง",
  "新闻": "ข่าว",
  "个人中心": "ศูนย์ส่วนตัว",
  "模拟账户": "บัญชีทดลอง",
  "可用资产": "สินทรัพย์ที่ใช้ได้",
  "入金": "ฝากเงิน",
  "出金": "ถอนเงิน",
  "账单详情": "รายละเอียดบิล",
  "重置模拟账户": "รีเซ็ตบัญชีทดลอง",
  "钱包": "กระเป๋าเงิน",
  "实名认证": "การยืนยันตัวตน",
  "邀请好友": "เชิญเพื่อน",
  "修改密码": "เปลี่ยนรหัสผ่าน",
  "投诉邮箱": "อีเมลการร้องเรียน",
  "其它": "อื่นๆ",
  "公告": "ประกาศ",
  "语言": "ภาษา",
  "是否退出登录": "คุณแน่ใจหรือไม่ว่าต้องการออกจากระบบ?",
  "复制": "คัดลอก",
  "复制成功": "คัดลอกสำเร็จ",
  "发送验证码成功": "ส่งรหัสยืนยันสำเร็จ",
  "请输入密码": "กรุณากรอกรหัสผ่าน",
  "请再次输入密码": "กรุณากรอกรหัสผ่านอีกครั้ง",
  "复制邀请链接": "คัดลอกลิงค์เชิญ",
  "上传身份": "อัปโหลดรูปหน้าหลังของพาสปอร์ต/บัตรประชาชน",
  "正面": "ด้านหน้า",
  "上传正面": "อัปโหลดด้านหน้าของพาสปอร์ต/บัตรประชาชน",
  "反面": "ด้านหลัง",
  "上传反面": "อัปโหลดด้านหลังของพาสปอร์ต/บัตรประชาชน",
  "完善信息": "กรุณากรอกข้อมูลส่วนตัว",
  "输入姓名": "กรุณากรอกชื่อของคุณ",
  "身份证号": "หมายเลขพาสปอร์ต/บัตรประชาชน",
  "输入身份证号": "กรุณากรอกหมายเลขพาสปอร์ต/บัตรประชาชน",
  "收款货币": "สกุลเงินที่รับ",
  "银行名称": "ชื่อธนาคาร",
  "收款人账户": "บัญชีผู้รับเงิน",
  "货币": "สกุลเงิน",
  "分店名称": "ชื่อสาขา",
  "绑定银行卡": "เชื่อมโยงบัตรธนาคาร",
  "银行地址": "ที่อยู่ธนาคาร",
  "账号名称（假名）": "ชื่อบัญชี (ชื่อเล่น)",
  "绑定数字货币地址": "เชื่อมโยงที่อยู่สกุลเงินดิจิตอล",
  "余额": "ยอดเงิน",
  "联系客服": "ติดต่อฝ่ายบริการลูกค้า",
  "行情": "ตลาด",
  "其他": "อื่นๆ",
  "设置": "การตั้งค่า",
  "收款人": "ผู้รับเงิน",
  "添加": "เพิ่ม",
  "确认": "ยืนยัน",
  "取消": "ยกเลิก",
  "钱包地址": "ที่อยู่กระเป๋าเงิน",
  "请输入": "กรุณากรอก",
  "退出登录": "ออกจากระบบ",
  "充值数量": "จำนวนฝากเงิน",
  "提交": "ยืนยัน",
  "银行卡": "บัตรธนาคาร",
  "选择网络": "เลือกเครือข่าย",
  "数字货币": "สกุลเงินดิจิตอล",
  "上传验证": "อัปโหลดการยืนยัน",
  "提币地址": "ที่อยู่การถอนเงิน",
  "数量(USD)": "จำนวน (USD)",
  "备注": "หมายเหตุ",
  "手续费": "ค่าธรรมเนียม",
  "预计到账数量": "จำนวนที่คาดว่าจะได้รับ",
  "暂无数据": "ไม่มีข้อมูล",
  "入金记录": "บันทึกการฝากเงิน",
  "提币记录": "บันทึกการถอนเงิน",
  "验证码": "รหัสยืนยัน",
  "邀请码": "รหัสเชิญ",
  "提示": "คำแนะนำ",
  "请输入验证码": "กรุณากรอกรหัสยืนยัน",
  "姓名": "ชื่อ",
  "邮箱登录": "เข้าสู่ระบบด้วยอีเมล",
  "邮箱": "อีเมล",
  "密码": "รหัสผ่าน",
  "新用户": "ผู้ใช้ใหม่",
  "立即加入": "เข้าร่วมทันที",
  "忘记密码": "ลืมรหัสผ่าน",
  "登录": "เข้าสู่ระบบ",
  "无需开户,立即创建模拟账号进行交易!": "ไม่ต้องเปิดบัญชี, สร้างบัญชีทดลองเพื่อทำการเทรดทันที!",
  "电话": "โทรศัพท์",
  "再次输入密码": "กรุณากรอกรหัสผ่านอีกครั้ง",
  "职业": "อาชีพ",
  "年收入": "รายได้ประจำปี",
  "注册": "สมัครสมาชิก",
  "邮箱注册": "สมัครสมาชิกด้วยอีเมล",
  "发送": "ส่ง",
  "重置密码": "รีเซ็ตรหัสผ่าน",
  "是否重置": "คุณแน่ใจหรือไม่ว่าต้องการรีเซ็ตรหัสผ่าน?",
  "已重置": "รีเซ็ตแล้ว",
  "名称": "ชื่อ",
  "注册成功": "สมัครสมาชิกสำเร็จ",
  "加载中": "กำลังโหลด",
  "您还未登录，请先登录": "คุณยังไม่ได้เข้าสู่ระบบ กรุณาเข้าสู่ระบบก่อน",
  "退出成功": "ออกจากระบบสำเร็จ",
  "市价": "ราคาตลาด",
  "挂单": "คำสั่งรอ",
  "倍数": "ตัวคูณ",
  "止盈": "จุดทำกำไร",
  "止损": "จุดตัดขาดทุน",
  "买入数量": "จำนวนซื้อ",
  "每手": "ต่อสัญญา",
  "预估手续费": "ค่าธรรมเนียมที่ประมาณการ",
  "预估保证金": "มาร์จิ้นที่ประมาณการ",
  "买入": "ซื้อ",
  "卖出": "ขาย",
  "您的订单已确认": "คำสั่งของคุณได้รับการยืนยันแล้ว",
  "订单页面": "หน้าคำสั่ง",
  "盈亏": "กำไร/ขาดทุน",
  "当前保证金": "มาร์จิ้นปัจจุบัน",
  "订单详情": "รายละเอียดคำสั่ง",
  "保证金": "มาร์จิ้น",
  "平仓": "ปิดตำแหน่ง",
  "修改": "แก้ไข",
  "手数": "จำนวนสัญญา",
  "暂无数据": "ไม่มีข้อมูล",
  "历史": "ประวัติ",
  "价格": "ราคา",
  "请输入价格": "กรุณากรอกราคา",
  "手": "สัญญา",
  "搜索结果": "ผลการค้นหา",
  "搜索产品": "ค้นหาผลิตภัณฑ์",
  "持仓": "ตำแหน่งเปิด",
  "语言": "ภาษา",
  "审核中": "กำลังตรวจสอบ",
  "请上传证件照正/反面": "กรุณาอัปโหลดรูปถ่ายหน้าหลังของเอกสาร",
  "上传成功": "อัปโหลดสำเร็จ",
  "上传失败": "อัปโหลดล้มเหลว",
  "提交成功": "ส่งสำเร็จ",
  "删除": "ลบ",
  "删除成功": "ลบสำเร็จ",
  "提币": "ถอนเงิน",
  "缺少参数或传值错误": "ขาดพารามิเตอร์หรือมีข้อผิดพลาดในการส่งค่า",
  "操作成功": "การดำเนินการสำเร็จ",
  "充值金额": "จำนวนเงินฝาก",
  "类型": "ประเภท",
  "状态": "สถานะ",
  "时间": "เวลา",
  "单位": "หน่วย",
  "最高价": "ราคาสูงสุด",
  "最低价": "ราคาต่ำสุด",
  "开盘价": "ราคากลางวันเปิด",
  "请注册或登录真实账号": "กรุณาลงทะเบียนหรือเข้าสู่ระบบด้วยบัญชีจริง",
  "没有更多了": "ไม่มีข้อมูลเพิ่มเติม",
  "提现金额": "จำนวนการถอน",
  "审核通过": "ตรวจสอบผ่าน",
  "审核不通过": "ตรวจสอบไม่ผ่าน",
  "重新提交": "ส่งใหม่",
  "止盈价": "ราคาทำกำไร",
  "止损价": "ราคาตัดขาดทุน",
  "总盈亏": "กำไร/ขาดทุนรวม",
  "撤单": "ยกเลิกคำสั่ง"
}
