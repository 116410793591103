export default {
  "首页": "Accueil",
  "订单": "Commande",
  "新闻": "Nouvelles",
  "个人中心": "Espace Personnel",
  "模拟账户": "Compte Démo",
  "可用资产": "Actifs Disponibles",
  "入金": "Dépôt",
  "出金": "Retrait",
  "账单详情": "Détails de la Facture",
  "重置模拟账户": "Réinitialiser le Compte Démo",
  "钱包": "Portefeuille",
  "实名认证": "Vérification d'Identité",
  "邀请好友": "Inviter des Amis",
  "修改密码": "Modifier le Mot de Passe",
  "投诉邮箱": "Courriel des Plaintes",
  "其它": "Autres",
  "公告": "Annonce",
  "语言": "Langue",
  "是否退出登录": "Voulez-vous vous déconnecter ?",
  "复制": "Copier",
  "复制成功": "Copie Réussie",
  "发送验证码成功": "Code de Vérification Envoyé",
  "请输入密码": "Veuillez Entrer le Mot de Passe",
  "请再次输入密码": "Veuillez Réentrer le Mot de Passe",
  "复制邀请链接": "Copier le Lien d'Invitation",
  "上传身份": "Télécharger le Recto et Verso de la Carte d'Identité/Passeport",
  "正面": "Recto",
  "上传正面": "Télécharger le Recto",
  "反面": "Verso",
  "上传反面": "Télécharger le Verso",
  "完善信息": "Complétez vos Informations",
  "输入姓名": "Entrez votre Nom",
  "身份证号": "Numéro d'Identité/Passeport",
  "输入身份证号": "Entrez votre Numéro d'Identité/Passeport",
  "收款货币": "Monnaie de Réception",
  "银行名称": "Nom de la Banque",
  "收款人账户": "Compte du Bénéficiaire",
  "货币": "Devise",
  "分店名称": "Nom de la Succursale",
  "绑定银行卡": "Lier la Carte Bancaire",
  "银行地址": "Adresse de la Banque",
  "账号名称（假名）": "Nom du Compte (Pseudonyme)",
  "绑定数字货币地址": "Lier l'Adresse de Cryptomonnaie",
  "余额": "Solde",
  "联系客服": "Contacter le Support",
  "行情": "Marché",
  "其他": "Autres",
  "设置": "Paramètres",
  "收款人": "Bénéficiaire",
  "添加": "Ajouter",
  "确认": "Confirmer",
  "取消": "Annuler",
  "钱包地址": "Adresse du Portefeuille",
  "请输入": "Veuillez Entrer",
  "退出登录": "Se Déconnecter",
  "充值数量": "Montant de Dépôt",
  "提交": "Soumettre",
  "银行卡": "Carte Bancaire",
  "选择网络": "Choisir le Réseau",
  "数字货币": "Cryptomonnaie",
  "上传验证": "Télécharger la Vérification",
  "提币地址": "Adresse de Retrait",
  "数量(USD)": "Montant (USD)",
  "备注": "Remarques",
  "手续费": "Frais",
  "预计到账数量": "Quantité Estimée à Recevoir",
  "暂无数据": "Pas de Données",
  "入金记录": "Historique des Dépôts",
  "提币记录": "Historique des Retraits",
  "验证码": "Code de Vérification",
  "邀请码": "Code d'Invitation",
  "提示": "Conseil",
  "请输入验证码": "Veuillez Entrer le Code de Vérification",
  "姓名": "Nom",
  "邮箱登录": "Connexion par Email",
  "邮箱": "Email",
  "密码": "Mot de Passe",
  "新用户": "Nouvel Utilisateur",
  "立即加入": "Rejoindre Maintenant",
  "忘记密码": "Mot de Passe Oublié",
  "登录": "Se Connecter",
  "无需开户,立即创建模拟账号进行交易!": "Aucune Inscription Nécessaire, Créez un Compte Démo pour Commencer à Trader !",
  "电话": "Téléphone",
  "再次输入密码": "Réentrez le Mot de Passe",
  "职业": "Profession",
  "年收入": "Revenu Annuel",
  "注册": "S'inscrire",
  "邮箱注册": "Inscription par Email",
  "发送": "Envoyer",
  "重置密码": "Réinitialiser le Mot de Passe",
  "是否重置": "Voulez-vous Réinitialiser ?",
  "已重置": "Réinitialisé",
  "名称": "Nom",
  "注册成功": "Inscription Réussie",
  "加载中": "Chargement",
  "您还未登录，请先登录": "Vous n'êtes pas Connecté, Veuillez d'abord vous Connecter",
  "退出成功": "Déconnexion Réussie",
  "市价": "Prix du Marché",
  "挂单": "Ordre Limite",
  "倍数": "Multiplicateur",
  "止盈": "Take Profit",
  "止损": "Stop Loss",
  "买入数量": "Quantité d'Achat",
  "每手": "Par Lot",
  "预估手续费": "Frais Estimés",
  "预估保证金": "Marge Estimée",
  "买入": "Acheter",
  "卖出": "Vendre",
  "您的订单已确认": "Votre Commande a été Confirmée",
  "订单页面": "Page de Commande",
  "盈亏": "Gains et Pertes",
  "当前保证金": "Marge Actuelle",
  "订单详情": "Détails de la Commande",
  "保证金": "Marge",
  "平仓": "Clôturer la Position",
  "修改": "Modifier",
  "手数": "Taille du Lot",
  "暂无数据": "Pas de Données",
  "历史": "Historique",
  "价格": "Prix",
  "请输入价格": "Veuillez Entrer le Prix",
  "手": "Lot",
  "搜索结果": "Résultats de Recherche",
  "搜索产品": "Rechercher un Produit",
  "持仓": "Position",
  "语言": "Langue",
  "审核中": "En Révision",
  "请上传证件照正/反面": "Téléchargez le Recto et Verso du Document d'Identité",
  "上传成功": "Téléchargement Réussi",
  "上传失败": "Échec du Téléchargement",
  "提交成功": "Soumission Réussie",
  "删除": "Supprimer",
  "删除成功": "Suppression Réussie",
  "提币": "Retrait",
  "缺少参数或传值错误": "Paramètre Manquant ou Erreur de Valeur",
  "操作成功": "Opération Réussie",
  "充值金额": "Montant du Dépôt",
  "类型": "Type",
  "状态": "Statut",
  "时间": "Heure",
  "单位": "Unité",
  "最高价": "Prix Maximum",
  "最低价": "Prix Minimum",
  "开盘价": "Prix d'Ouverture",
  "请注册或登录真实账号": "Veuillez vous Inscrire ou vous Connecter à un Compte Réel",
  "没有更多了": "Pas Plus de Données",
  "提现金额": "Montant du Retrait",
  "审核通过": "Approuvé",
  "审核不通过": "Non Approuvé",
  "重新提交": "Soumettre à Nouveau",
  "止盈价": "Prix de Take Profit",
  "止损价": "Prix de Stop Loss",
  "总盈亏": "Gains et Pertes Totaux",
  "撤单": "Annuler la Commande"
}
