import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getLanguage } from '@/utils/auth'

// language
import hkLocale from './zh-hk' // 繁中
import enLocale from './en' // 英文
import jpLocale from './ja-jp' // 日语
import arLocale from './ar' // 阿拉伯语
import frLocale from './fr' // 法语
import deLocale from './de' // 德语
import esLocale from './es' // 西班牙语
import itLocale from './it' // 意大利语
import hiLocale from './hi' // 印地语
import idLocale from './id' // 印尼语
import thLocale from './th' // 泰语
import trLocale from './tr' // 土耳其
import viLocale from './vi' // 越南
import ruLocale from './ru' // 俄语
import koLocale from './ko' // 韩语
import ptLocale from './pt' // 韩语

Vue.use(VueI18n)

const messages = {
  'zh_TW': { ...hkLocale },
  'en': { ...enLocale },
  'ja': { ...jpLocale },
  'ar': { ...arLocale },
  'fr': { ...frLocale },
  'de': { ...deLocale },
  'es': { ...esLocale },
  'it': { ...itLocale },
  'hi': { ...hiLocale },
  'id': { ...idLocale },
  'th': { ...thLocale },
  'tr': { ...trLocale },
  'vi': { ...viLocale },
  'ru': { ...ruLocale },
  'ko': { ...koLocale },
  'pt': { ...ptLocale },
}

function getLang() {
  const defaultLanguage = localStorage.getItem('lang') || 'en'
  // const chooseLanguage = getLanguage();
  // if (chooseLanguage) return chooseLanguage
  // if has not choose language
  return defaultLanguage
}

const i18n = new VueI18n({
  // set locale
  locale: getLang(),
  // set locale messages
  messages
})

export default i18n
