import Vue from 'vue'
import store from './../store/index.js';
const la = navigator.language.toLowerCase();
let language = 'en';
if (la.indexOf('zh') != -1) language = 'zh';
const URL = window.global;
let BASE_URL = URL[Object.keys(URL)[1]];
for (let key in URL) {
  if (key == language) BASE_URL = URL[key];
}
// // const ParameterWs = window.global.parameterWs;
import { PARAMETER, LOGIN } from './../config/global_data';
let wsConnectionOrder = {
  $wsOrder: null,
  lockReturn: false,
  timeoutObj: null,
  timeoutNum: null,
  //初始化webSocket长连接
  initWebSocket: function () {
    this.$wsOrder = new WebSocket(BASE_URL);
    this.$wsOrder.onopen = this.wsOpen;
    this.$wsOrder.onclose = this.wsClose;
    this.$wsOrder.onmessage = this.wsMsg;
    this.$wsOrder.onerror = this.wsError;
  },
  //打开websocket
  wsOpen: function (e) {
    //开始websocket心跳
    wsConnectionOrder.startWsHeartbeat();
    //进入房间
    this.send(JSON.stringify(PARAMETER));
    this.send(JSON.stringify(LOGIN));
    console.log('orderws success', JSON.stringify(PARAMETER))
    console.log('orderws success', JSON.stringify(LOGIN))
  },
  wsSend() {
    let _this = this
    _this.$wsOrder.send(JSON.stringify(PARAMETER));
    wsConnectionOrder.startWsHeartbeat();
    console.log('sendws success', JSON.stringify(PARAMETER))
  },
  wsClose: function () {
    if (this.$wsOrder) {
      this.$wsOrder.close() // 关闭websocket
      this.$wsOrder.onclose() // 关闭websocket
    }
  },
  wsMsg: function (msg) {
    //服务端发送来的消息存到vuex（先存储消息再重置心跳）
    store.commit('web_socket_msg', msg)
    //每次接收到服务端消息后 重置websocket心跳
    wsConnectionOrder.resetHeartbeat();
  },
  wsError: function (err) {
    wsConnectionOrder.reconnect()
  },
  //重启websocket
  reconnect: function () {
    console.log('重启函数')
    let _this = this;
    if (_this.lockReturn) {
      return;
    }
    _this.lockReturn = true;
    _this.timeoutNum && clearTimeout(_this.timeoutNum);
    _this.timeoutNum = setTimeout(function () {
      //_this.wsClose();
      _this.initWebSocket();
      _this.lockReturn = false;
    }, 1000);
  },
  //开启websocket
  startWsHeartbeat: function () {
    let _this = this;
    _this.timeoutObj && clearInterval(_this.timeoutObj);
    _this.timeoutObj = setInterval(function () {
      // _this.$wsOrder.send('PING');
      if (_this.$wsOrder.readyState != 1) {
        _this.reconnect()
      }
    }, 2000)
  },
  //重置websocket心跳
  resetHeartbeat: function () {
    let _this = this;
    clearInterval(_this.timeoutObj);
    _this.startWsHeartbeat()
  }
};

//抛出websocket对象
export default {
  wsConnectionOrder
}
