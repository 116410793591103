import Vue from 'vue'
import Vuex from 'vuex'
import {
  getToken,
  setToken,
  removeToken,
  getMember,
  setMember,
  removeMember,
  getEnums,
  setEnums,
  removeEnums,
  getLanguage,
  setLanguage,
  getPushStatus,
  setPushStatus,
  removePushStatus,
  getNoPushAccount,
  setNoPushAccount
} from '@/utils/auth'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: getToken(),
    member: getMember(),
    enums: getEnums(),
    language: getLanguage(),
    pushed: getPushStatus(),
    noPushAccount: getNoPushAccount(),
    orderWebSocketMsg: '',//存放的键值对就是所要管理的状态(全局可以访问的state对象)
    identityInfo: {},
    serviceLink: '',
    fiatCurrencyPayment: '',
    contractName: {
      from_coin: 'BTC',
      to_coin: 'USDT',
    },
  },
  getters: {
    token: state => state.token,
    enums: state => state.enums,
    member: state => state.member,
    language: state => state.language,
    pushed: state => state.pushed,
    noPush: state => (state.noPushAccount == state.member.username),
    identityInfo: state => state.identityInfo,
    serviceLink: state => state.serviceLink,
    fiatCurrencyPayment: state => state.fiatCurrencyPayment,
    contractName: state => state.contractName,
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_MEMBER: (state, member) => {
      state.member = member
    },
    SET_ENUM: (state, enums) => {
      state.enums = enums
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language
    },
    SET_PUSHED: (state, pushed = true) => {
      state.pushed = pushed
    },
    web_socket_msg(state, msg) {//自定义改变state初始值的方法，这里面的参数除了state之外还可以再传额外的参数(变量或对象);
      state.orderWebSocketMsg = msg;
    },
    SET_IDENTITY: (state, identityInfo) => {
      state.identityInfo = identityInfo
    },
    SET_SERVICELINK: (state, serviceLink) => {
      state.serviceLink = serviceLink
    },
    SET_FIATCURRENCYPAYMENT: (state, fiatCurrencyPayment) => {
      state.fiatCurrencyPayment = fiatCurrencyPayment
    },
    SET_CONTRACTNAME: (state, contractName) => {
      state.contractName = contractName
    },
  },
  actions: {
    login({ commit }, data) {
      commit('SET_TOKEN', data.access_token)
      commit('SET_MEMBER', data.member)
      setToken(data.access_token)
      setPushStatus(false)
      setMember(JSON.stringify(data.member))
    },

    logout({ commit }) {
      commit('SET_TOKEN', '')
      commit('SET_ENUM', null)
      commit('SET_MEMBER', null)
      commit('SET_PUSHED', false)
      removeToken()
      removeEnums()
      removeMember()
      removePushStatus()
      location.reload()
    },

    setEnums({ commit }, data) {
      commit('SET_ENUM', data)
      setEnums(JSON.stringify(data))
    },

    setLanguage({ commit }, data) {
      commit('SET_LANGUAGE', data)
      setLanguage(data)
      location.reload()
    },

    setPushed({ commit }) {
      commit('SET_PUSHED', true)
      setPushStatus(true)
    },

    setAlwayPushed({ state, dispatch }) {
      dispatch('setPushed')
      setNoPushAccount(state.member.username)
    },

    setIdentity({ commit }, data) {
      commit('SET_IDENTITY', data)
    },
    setServiceLink({ commit }, data) {
      commit('SET_SERVICELINK', data)
    },
    setFiatCurrencyPayment({ commit }, data) {
      commit('SET_FIATCURRENCYPAYMENT', data)
    },
    setContractName({ commit }, data) {
      commit('SET_CONTRACTNAME', data)
    },

    // remove token
    resetInfo({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_ENUM', null)
        commit('SET_MEMBER', null)
        commit('SET_PUSHED', false)
        removeToken()
        removeEnums()
        removeMember()
        removePushStatus()
        resolve()
      })
    },
    //
    updateSignInfo(context, signInfo) {
      context.commit("updateSignInfo", signInfo);

    },
    updateReload(context, num) {
      context.commit("updateReload", num);
    },
    updateDoct(context, num) {
      context.commit("updateDoct", num);
    },
  }
})

export default store
