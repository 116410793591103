<template>
  <div class="">
    <van-tabbar route v-model="active" @change="onChange" class="tabbarclass">
      <van-tabbar-item replace to="/home">
        <template #icon="props">
          <div class="flex justify-center">
            <img v-show="props.active" class="icon" src="../assets/xq_on.png" alt="" srcset="" />
            <img v-show="!props.active" class="icon" src="../assets/xq.png" alt="" srcset="" />
          </div>
          <div class="tabbar-text" :class="props.active ? 'tabbar-color-on' : 'tabbar-color'">
            {{ $i18n.t("行情") }}
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/order">
        <template #icon="props">
          <div class="flex justify-center">
            <img v-show="props.active" class="icon" src="../assets/dingdan_on.png" alt="" srcset="" />
            <img v-show="!props.active" class="icon" src="../assets/dingdan.png" alt="" srcset="" />
          </div>
          <div class="tabbar-text" :class="props.active ? 'tabbar-color-on' : 'tabbar-color'">
            {{ $i18n.t("订单") }}
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/currency">
        <!-- <span>自定义</span> -->
        <template>
          <div class="van-tabbar-item relative">
            <div class="zdyicon h-50 w-50">
              <!-- <van-icon name="exchange" size="30" color="#ffffff" /> -->
              <img class="icon" style="width: 14vw;height: 14vw !important;" src="../assets/zhonjian.png" alt=""
                srcset="" />
            </div>
          </div>
        </template>
      </van-tabbar-item>
      <!-- <van-tabbar-item replace to="/news" icon="tosend">{{
        $t("新闻")
      }}</van-tabbar-item> -->
      <van-tabbar-item replace to="/news">
        <template #icon="props">
          <div class="flex justify-center">
            <img v-show="props.active" class="icon" src="../assets/xw_on.png" alt="" srcset="" />
            <img v-show="!props.active" class="icon" src="../assets/xw.png" alt="" srcset="" />
          </div>
          <div class="tabbar-text" :class="props.active ? 'tabbar-color-on' : 'tabbar-color'">
            {{ $i18n.t("新闻") }}
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/my">
        <template #icon="props">
          <div class="flex justify-center">
            <img v-show="props.active" class="icon" src="../assets/my_on.png" alt="" srcset="" />
            <img v-show="!props.active" class="icon" src="../assets/my.png" alt="" srcset="" />
          </div>
          <div class="tabbar-text" :class="props.active ? 'tabbar-color-on' : 'tabbar-color'">
            {{ $i18n.t("个人中心") }}
          </div>
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      icon: {
        active: "",
        inactive: "",
      },
    };
  },
  name: "HelloWorld",
  props: {
    msg: String,
  },
  ounted() { },
  methods: {
    onChange(e) {
      this.active = e;
      this.$forceUpdate();
      // console.log(e, this)
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.tabbarclass {
  /* height: 60px; */
  height: 15.46667vw !important;
  box-shadow: 0 2.93333vw 7.73333vw rgba(161, 161, 161, 0.18);
  z-index: 999 !important;
}

.icon {
  width: 8vw;
  height: 8vw !important;
}

/* .van-tabbar-item--active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.tabbar-text {
  text-align: center;
  margin-top: 4px;
  font-size: 12px;
}

.tabbar-color {
  color: rgb(206, 206, 206);
}

.tabbar-color-on {
  color: rgb(1, 102, 252);
}

.van-tabbar-item__icon {
  margin-bottom: 0 !important;
}

.zdyicon {
  background: #0166fc;
  position: absolute;
  bottom: 0vw;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
