import { API_VERSION } from '@/config/global_data'
import {
  requestGet,
  requestDelete,
  requestPost,
  requestPut
} from './axios'

export default {
  requestGet,
  requestDelete,
  requestPost,
  requestPut
}
