export default {
  "首页": "ホーム",
  "订单": "注文",
  "新闻": "ニュース",
  "个人中心": "マイページ",
  "模拟账户": "デモアカウント",
  "可用资产": "利用可能な資産",
  "入金": "入金",
  "出金": "出金",
  "账单详情": "請求明細",
  "重置模拟账户": "デモアカウントのリセット",
  "钱包": "ウォレット",
  "实名认证": "本人確認",
  "邀请好友": "友達を招待",
  "修改密码": "パスワード変更",
  "投诉邮箱": "苦情メール",
  "其它": "その他",
  "公告": "お知らせ",
  "语言": "言語",
  "是否退出登录": "ログアウトしますか？",
  "复制": "コピー",
  "复制成功": "コピー成功",
  "发送验证码成功": "認証コードの送信成功",
  "请输入密码": "パスワードを入力してください",
  "请再次输入密码": "パスワードを再度入力してください",
  "复制邀请链接": "招待リンクをコピー",
  "上传身份": "パスポート/身分証明書の表裏をアップロードしてください",
  "正面": "表面",
  "上传正面": "パスポート/身分証明書の表面をアップロード",
  "反面": "裏面",
  "上传反面": "パスポート/身分証明書の裏面をアップロード",
  "完善信息": "個人情報を入力してください",
  "输入姓名": "名前を入力してください",
  "身份证号": "パスポート/身分証明書番号",
  "输入身份证号": "パスポート/身分証明書番号を入力してください",
  "收款货币": "受取通貨",
  "银行名称": "銀行名",
  "收款人账户": "受取人アカウント",
  "货币": "通貨",
  "分店名称": "支店名",
  "绑定银行卡": "銀行カードをリンク",
  "银行地址": "銀行の住所",
  "账号名称（假名）": "アカウント名（ニックネーム）",
  "绑定数字货币地址": "暗号通貨アドレスをリンク",
  "余额": "残高",
  "联系客服": "カスタマーサポートに連絡",
  "行情": "マーケット",
  "其他": "その他",
  "设置": "設定",
  "收款人": "受取人",
  "添加": "追加",
  "确认": "確認",
  "取消": "キャンセル",
  "钱包地址": "ウォレットアドレス",
  "请输入": "入力してください",
  "退出登录": "ログアウト",
  "充值数量": "入金数量",
  "提交": "送信",
  "银行卡": "銀行カード",
  "选择网络": "ネットワーク選択",
  "数字货币": "暗号通貨",
  "上传验证": "検証をアップロード",
  "提币地址": "出金アドレス",
  "数量(USD)": "数量（USD）",
  "备注": "備考",
  "手续费": "手数料",
  "预计到账数量": "予想受取額",
  "暂无数据": "データはありません",
  "入金记录": "入金履歴",
  "提币记录": "出金履歴",
  "验证码": "認証コード",
  "邀请码": "招待コード",
  "提示": "ヒント",
  "请输入验证码": "認証コードを入力してください",
  "姓名": "名前",
  "邮箱登录": "メールでログイン",
  "邮箱": "メール",
  "密码": "パスワード",
  "新用户": "新規ユーザー",
  "立即加入": "今すぐ参加",
  "忘记密码": "パスワードを忘れた",
  "登录": "ログイン",
  "无需开户,立即创建模拟账号进行交易!": "アカウントを開設せず、すぐにデモアカウントを作成して取引を開始！",
  "电话": "電話",
  "再次输入密码": "パスワードを再入力してください",
  "职业": "職業",
  "年收入": "年収",
  "注册": "登録",
  "邮箱注册": "メールで登録",
  "发送": "送信",
  "重置密码": "パスワードリセット",
  "是否重置": "リセットしますか？",
  "已重置": "リセット済み",
  "名称": "名前",
  "注册成功": "登録成功",
  "加载中": "読み込み中",
  "您还未登录，请先登录": "まだログインしていません。ログインしてください",
  "退出成功": "ログアウト成功",
  "市价": "市場価格",
  "挂单": "指値注文",
  "倍数": "倍数",
  "止盈": "利益確定",
  "止损": "損切り",
  "买入数量": "購入数量",
  "每手": "1ロットあたり",
  "预估手续费": "予想手数料",
  "预估保证金": "予想証拠金",
  "买入": "購入",
  "卖出": "売却",
  "您的订单已确认": "注文が確認されました",
  "订单页面": "注文ページ",
  "盈亏": "利益/損失",
  "当前保证金": "現在の証拠金",
  "订单详情": "注文詳細",
  "保证金": "証拠金",
  "平仓": "決済",
  "修改": "変更",
  "手数": "ロット数",
  "暂无数据": "データなし",
  "历史": "履歴",
  "价格": "価格",
  "请输入价格": "価格を入力してください",
  "手": "ロット",
  "搜索结果": "検索結果",
  "搜索产品": "製品検索",
  "持仓": "ポジション",
  "语言": "言語",
  "审核中": "審査中",
  "请上传证件照正/反面": "証明書の表裏をアップロードしてください",
  "上传成功": "アップロード成功",
  "上传失败": "アップロード失敗",
  "提交成功": "送信成功",
  "删除": "削除",
  "删除成功": "削除成功",
  "提币": "出金",
  "缺少参数或传值错误": "パラメータが不足しているか、値の渡し方が間違っています",
  "操作成功": "操作成功",
  "充值金额": "入金額",
  "类型": "タイプ",
  "状态": "状態",
  "时间": "時間",
  "单位": "単位",
  "最高价": "最高価格",
  "最低价": "最低価格",
  "开盘价": "始値",
  "请注册或登录真实账号": "本物のアカウントで登録またはログインしてください",
  "没有更多了": "これ以上ありません",
  "提现金额": "出金額",
  "审核通过": "審査通過",
  "审核不通过": "審査不通過",
  "重新提交": "再提出",
  "止盈价": "利益確定価格",
  "止损价": "損切り価格",
  "总盈亏": "総利益/損失",
  "撤单": "注文キャンセル"
}
