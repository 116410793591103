export default {
  "首页": "Início",
  "订单": "Pedidos",
  "新闻": "Notícias",
  "个人中心": "Central de usuário",
  "模拟账户": "Conta demo",
  "可用资产": "Ativos disponíveis",
  "入金": "Depósito",
  "出金": "Retirada",
  "账单详情": "Detalhes da fatura",
  "重置模拟账户": "Redefinir conta demo",
  "钱包": "Carteira",
  "实名认证": "Autenticação real",
  "邀请好友": "Convidar amigos",
  "修改密码": "Alterar senha",
  "投诉邮箱": "E-mail de reclamação",
  "其它": "Outros",
  "公告": "Anúncios",
  "语言": "Idioma",
  "是否退出登录": "Deseja sair?",
  "复制": "Copiar",
  "复制成功": "Copiado com sucesso",
  "发送验证码成功": "Código de verificação enviado com sucesso",
  "请输入密码": "Digite a senha",
  "请再次输入密码": "Digite a senha novamente",
  "复制邀请链接": "Copiar link de convite",
  "上传身份": "Faça o upload do verso e anverso do passaporte/ID",
  "正面": "Frente",
  "上传正面": "Faça o upload do verso do passaporte/ID",
  "反面": "Verso",
  "上传反面": "Faça o upload do verso do passaporte/ID",
  "完善信息": "Complete as informações pessoais",
  "输入姓名": "Digite seu nome",
  "身份证号": "Número do passaporte/ID",
  "输入身份证号": "Digite o número do passaporte/ID",
  "收款货币": "Moeda de recebimento",
  "银行名称": "Nome do banco",
  "收款人账户": "Conta do destinatário",
  "货币": "Moeda",
  "分店名称": "Nome da filial",
  "绑定银行卡": "Vincular cartão bancário",
  "银行地址": "Endereço do banco",
  "账号名称（假名）": "Nome da conta (apelido)",
  "绑定数字货币地址": "Vincular endereço de moeda digital",
  "余额": "Saldo",
  "联系客服": "Atendimento ao cliente",
  "行情": "Cotação",
  "其他": "Outros",
  "设置": "Configurações",
  "收款人": "Destinatário",
  "添加": "Adicionar",
  "确认": "Confirmar",
  "取消": "Cancelar",
  "钱包地址": "Endereço da carteira",
  "请输入": "Digite",
  "退出登录": "Sair",
  "充值数量": "Quantidade de depósito",
  "提交": "Enviar",
  "银行卡": "Cartão bancário",
  "选择网络": "Escolher rede",
  "数字货币": "Criptomoeda",
  "上传验证": "Upload de verificação",
  "提币地址": "Endereço de retirada",
  "数量(USD)": "Quantidade (USD)",
  "备注": "Observações",
  "手续费": "Taxa de serviço",
  "预计到账数量": "Quantidade estimada para recebimento",
  "暂无数据": "Sem dados",
  "入金记录": "Registro de depósitos",
  "提币记录": "Registro de retiradas",
  "验证码": "Código de verificação",
  "邀请码": "Código de convite",
  "提示": "Aviso",
  "请输入验证码": "Digite o código de verificação",
  "姓名": "Nome",
  "邮箱登录": "Login com e-mail",
  "邮箱": "E-mail",
  "密码": "Senha",
  "新用户": "Novo usuário",
  "立即加入": "Junte-se agora",
  "忘记密码": "Esqueceu a senha?",
  "登录": "Entrar",
  "无需开户,立即创建模拟账号进行交易!": "Não é necessário abrir uma conta, crie uma conta demo para começar a negociar!",
  "电话": "Telefone",
  "再次输入密码": "Digite a senha novamente",
  "职业": "Profissão",
  "年收入": "Renda anual",
  "注册": "Registrar",
  "邮箱注册": "Registrar com e-mail",
  "发送": "Enviar",
  "重置密码": "Redefinir senha",
  "是否重置": "Deseja redefinir?",
  "已重置": "Redefinido",
  "名称": "Nome",
  "注册成功": "Registro bem-sucedido",
  "加载中": "Carregando",
  "您还未登录，请先登录": "Você ainda não está logado, por favor, faça login",
  "退出成功": "Logout bem-sucedido",
  "市价": "Preço de mercado",
  "挂单": "Ordem pendente",
  "倍数": "Multiplicador",
  "止盈": "Lucro desejado",
  "止损": "Perda máxima",
  "买入数量": "Quantidade a comprar",
  "每手": "Por lote",
  "预估手续费": "Taxa de serviço estimada",
  "预估保证金": "Margem estimada",
  "买入": "Comprar",
  "卖出": "Vender",
  "您的订单已确认": "Seu pedido foi confirmado",
  "订单页面": "Página de pedidos",
  "盈亏": "Lucro/Perda",
  "当前保证金": "Margem atual",
  "订单详情": "Detalhes do pedido",
  "保证金": "Margem",
  "平仓": "Fechar posição",
  "修改": "Alterar",
  "手数": "Lotes",
  "暂无数据": "Sem dados",
  "历史": "Histórico",
  "价格": "Preço",
  "请输入价格": "Digite o preço",
  "手": "Lote",
  "搜索结果": "Resultados da pesquisa",
  "搜索产品": "Pesquisar produto",
  "持仓": "Posição",
  "语言": "Idioma",
  "审核中": "Em revisão",
  "请上传证件照正/反面": "Por favor, envie as fotos frente e verso do documento",
  "上传成功": "Upload bem-sucedido",
  "上传失败": "Falha no upload",
  "提交成功": "Envio bem-sucedido",
  "删除": "Excluir",
  "删除成功": "Exclusão bem-sucedida",
  "提币": "Retirar",
  "缺少参数或传值错误": "Parâmetros faltando ou erro na transmissão de valores",
  "操作成功": "Operação bem-sucedida",
  "充值金额": "Valor do depósito",
  "类型": "Tipo",
  "状态": "Status",
  "时间": "Hora",
  "单位": "Unidade",
  "最高价": "Preço mais alto",
  "最低价": "Preço mais baixo",
  "开盘价": "Preço de abertura",
  "请注册或登录真实账号": "Por favor, registre-se ou faça login em uma conta real",
  "没有更多了": "Não há mais dados",
  "提现金额": "Valor da retirada",
  "审核通过": "Revisão aprovada",
  "审核不通过": "Revisão não aprovada",
  "重新提交": "Reenviar",
  "止盈价": "Preço de lucro desejado",
  "止损价": "Preço de stop loss",
  "总盈亏": "Lucro/Perda total",
  "撤单": "Cancelar pedido"
}
