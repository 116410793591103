export default {
  "首页": "Home",
  "订单": "Ordine",
  "新闻": "Notizie",
  "个人中心": "Centro Personale",
  "模拟账户": "Account Demo",
  "可用资产": "Attivo Disponibile",
  "入金": "Deposito",
  "出金": "Prelievo",
  "账单详情": "Dettagli Fattura",
  "重置模拟账户": "Resetta Account Demo",
  "钱包": "Portafoglio",
  "实名认证": "Verifica Identità",
  "邀请好友": "Invita Amici",
  "修改密码": "Cambia Password",
  "投诉邮箱": "Email Reclami",
  "其它": "Altro",
  "公告": "Avviso",
  "语言": "Lingua",
  "是否退出登录": "Uscire dal login?",
  "复制": "Copia",
  "复制成功": "Copia riuscita",
  "发送验证码成功": "Codice di verifica inviato con successo",
  "请输入密码": "Inserisci la password",
  "请再次输入密码": "Inserisci la password di nuovo",
  "复制邀请链接": "Copia il link di invito",
  "上传身份": "Carica il fronte e il retro del passaporto/carta d'identità",
  "正面": "Fronte",
  "上传正面": "Carica il fronte",
  "反面": "Retro",
  "上传反面": "Carica il retro",
  "完善信息": "Completa le informazioni personali",
  "输入姓名": "Inserisci il tuo nome",
  "身份证号": "Numero documento/Passaporto",
  "输入身份证号": "Inserisci il tuo numero di documento",
  "收款货币": "Valuta di Ricezione",
  "银行名称": "Nome Banca",
  "收款人账户": "Account del Ricevente",
  "货币": "Valuta",
  "分店名称": "Nome Filiale",
  "绑定银行卡": "Collega Carta Bancaria",
  "银行地址": "Indirizzo Banca",
  "账号名称（假名）": "Nome Account (alias)",
  "绑定数字货币地址": "Collega Indirizzo Criptovaluta",
  "余额": "Saldo",
  "联系客服": "Contatta il Servizio Clienti",
  "行情": "Mercato",
  "其他": "Altro",
  "设置": "Impostazioni",
  "收款人": "Destinatario",
  "添加": "Aggiungi",
  "确认": "Conferma",
  "取消": "Annulla",
  "钱包地址": "Indirizzo Portafoglio",
  "请输入": "Inserisci",
  "退出登录": "Esci",
  "充值数量": "Quantità Deposito",
  "提交": "Invia",
  "银行卡": "Carta Bancaria",
  "选择网络": "Scegli Rete",
  "数字货币": "Criptovaluta",
  "上传验证": "Carica Verifica",
  "提币地址": "Indirizzo Prelievo",
  "数量(USD)": "Quantità (USD)",
  "备注": "Note",
  "手续费": "Commissioni",
  "预计到账数量": "Quantità Stimata in Arrivo",
  "暂无数据": "Nessun dato",
  "入金记录": "Storico Depositi",
  "提币记录": "Storico Prelievi",
  "验证码": "Codice di Verifica",
  "邀请码": "Codice Invito",
  "提示": "Avviso",
  "请输入验证码": "Inserisci il codice di verifica",
  "姓名": "Nome",
  "邮箱登录": "Login con Email",
  "邮箱": "Email",
  "密码": "Password",
  "新用户": "Nuovo Utente",
  "立即加入": "Unisciti Ora",
  "忘记密码": "Password Dimenticata",
  "登录": "Accedi",
  "无需开户,立即创建模拟账号进行交易!": "Non è necessario aprire un account, crea subito un account demo per fare trading!",
  "电话": "Telefono",
  "再次输入密码": "Inserisci la password di nuovo",
  "职业": "Professione",
  "年收入": "Reddito Annuale",
  "注册": "Registrati",
  "邮箱注册": "Registrazione con Email",
  "发送": "Invia",
  "重置密码": "Resetta Password",
  "是否重置": "Vuoi resettare?",
  "已重置": "Resettato",
  "名称": "Nome",
  "注册成功": "Registrazione Avvenuta con Successo",
  "加载中": "Caricamento in Corso",
  "您还未登录，请先登录": "Non hai effettuato l'accesso, effettua prima il login",
  "退出成功": "Uscita Riuscita",
  "市价": "Prezzo di Mercato",
  "挂单": "Ordine Limit",
  "倍数": "Moltiplicatore",
  "止盈": "Fissa Profitti",
  "止损": "Limita Perdite",
  "买入数量": "Quantità Acquisto",
  "每手": "Per Lotto",
  "预估手续费": "Commissioni Stimata",
  "预估保证金": "Margine Stimato",
  "买入": "Acquista",
  "卖出": "Vendi",
  "您的订单已确认": "Il tuo ordine è stato confermato",
  "订单页面": "Pagina Ordine",
  "盈亏": "Profitto/Perdita",
  "当前保证金": "Margine Attuale",
  "订单详情": "Dettagli Ordine",
  "保证金": "Margine",
  "平仓": "Chiudi Posizione",
  "修改": "Modifica",
  "手数": "Numero di Lotti",
  "暂无数据": "Nessun Dato",
  "历史": "Storico",
  "价格": "Prezzo",
  "请输入价格": "Inserisci il prezzo",
  "手": "Lotto",
  "搜索结果": "Risultati della Ricerca",
  "搜索产品": "Cerca Prodotti",
  "持仓": "Posizioni",
  "语言": "Lingua",
  "审核中": "In Revisione",
  "请上传证件照正/反面": "Carica la foto del documento fronte/retro",
  "上传成功": "Caricamento Riuscito",
  "上传失败": "Caricamento Fallito",
  "提交成功": "Invio Riuscito",
  "删除": "Elimina",
  "删除成功": "Eliminato con Successo",
  "提币": "Prelievo",
  "缺少参数或传值错误": "Parametro mancante o errore nel valore",
  "操作成功": "Operazione Riuscita",
  "充值金额": "Importo Deposito",
  "类型": "Tipo",
  "状态": "Stato",
  "时间": "Tempo",
  "单位": "Unità",
  "最高价": "Prezzo Massimo",
  "最低价": "Prezzo Minimo",
  "开盘价": "Prezzo di Apertura",
  "请注册或登录真实账号": "Si prega di registrarsi o effettuare il login con un account reale",
  "没有更多了": "Non ci sono più dati",
  "提现金额": "Importo Prelievo",
  "审核通过": "Revisione Approvata",
  "审核不通过": "Revisione Rifiutata",
  "重新提交": "Invia di Nuovo",
  "止盈价": "Prezzo di Fissaggio Profitti",
  "止损价": "Prezzo di Fissaggio Perdite",
  "总盈亏": "Totale Profitti/Perdite",
  "撤单": "Annulla Ordine"
}
