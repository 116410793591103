export default {
  "首页": "Beranda",
  "订单": "Pesanan",
  "新闻": "Berita",
  "个人中心": "Pusat Pribadi",
  "模拟账户": "Akun Demo",
  "可用资产": "Aset Tersedia",
  "入金": "Deposit",
  "出金": "Penarikan",
  "账单详情": "Detail Tagihan",
  "重置模拟账户": "Atur Ulang Akun Demo",
  "钱包": "Dompet",
  "实名认证": "Verifikasi Identitas",
  "邀请好友": "Undang Teman",
  "修改密码": "Ubah Kata Sandi",
  "投诉邮箱": "Email Pengaduan",
  "其它": "Lainnya",
  "公告": "Pengumuman",
  "语言": "Bahasa",
  "是否退出登录": "Keluar dari akun?",
  "复制": "Salin",
  "复制成功": "Berhasil disalin",
  "发送验证码成功": "Kode verifikasi berhasil dikirim",
  "请输入密码": "Silakan masukkan kata sandi",
  "请再次输入密码": "Silakan masukkan kata sandi lagi",
  "复制邀请链接": "Salin tautan undangan",
  "上传身份": "Unggah foto depan dan belakang paspor/identitas",
  "正面": "Depan",
  "上传正面": "Unggah bagian depan",
  "反面": "Belakang",
  "上传反面": "Unggah bagian belakang",
  "完善信息": "Lengkapi informasi pribadi",
  "输入姓名": "Masukkan nama Anda",
  "身份证号": "Nomor ID/Paspor",
  "输入身份证号": "Masukkan nomor ID Anda",
  "收款货币": "Mata Uang Penerima",
  "银行名称": "Nama Bank",
  "收款人账户": "Akun Penerima",
  "货币": "Mata Uang",
  "分店名称": "Nama Cabang",
  "绑定银行卡": "Hubungkan Kartu Bank",
  "银行地址": "Alamat Bank",
  "账号名称（假名）": "Nama Akun (alias)",
  "绑定数字货币地址": "Hubungkan Alamat Kripto",
  "余额": "Saldo",
  "联系客服": "Hubungi Layanan Pelanggan",
  "行情": "Pasar",
  "其他": "Lainnya",
  "设置": "Pengaturan",
  "收款人": "Penerima",
  "添加": "Tambahkan",
  "确认": "Konfirmasi",
  "取消": "Batal",
  "钱包地址": "Alamat Dompet",
  "请输入": "Silakan masukkan",
  "退出登录": "Keluar",
  "充值数量": "Jumlah Deposit",
  "提交": "Kirim",
  "银行卡": "Kartu Bank",
  "选择网络": "Pilih Jaringan",
  "数字货币": "Mata Uang Kripto",
  "上传验证": "Unggah Verifikasi",
  "提币地址": "Alamat Penarikan",
  "数量(USD)": "Jumlah (USD)",
  "备注": "Catatan",
  "手续费": "Biaya",
  "预计到账数量": "Perkiraan Jumlah Terima",
  "暂无数据": "Tidak ada data",
  "入金记录": "Riwayat Deposit",
  "提币记录": "Riwayat Penarikan",
  "验证码": "Kode Verifikasi",
  "邀请码": "Kode Undangan",
  "提示": "Peringatan",
  "请输入验证码": "Silakan masukkan kode verifikasi",
  "姓名": "Nama",
  "邮箱登录": "Login Email",
  "邮箱": "Email",
  "密码": "Kata Sandi",
  "新用户": "Pengguna Baru",
  "立即加入": "Bergabung Sekarang",
  "忘记密码": "Lupa Kata Sandi",
  "登录": "Login",
  "无需开户,立即创建模拟账号进行交易!": "Tanpa perlu akun, buat akun demo sekarang untuk trading!",
  "电话": "Telepon",
  "再次输入密码": "Masukkan kata sandi lagi",
  "职业": "Pekerjaan",
  "年收入": "Pendapatan Tahunan",
  "注册": "Daftar",
  "邮箱注册": "Daftar Email",
  "发送": "Kirim",
  "重置密码": "Atur Ulang Kata Sandi",
  "是否重置": "Apakah ingin direset?",
  "已重置": "Telah direset",
  "名称": "Nama",
  "注册成功": "Berhasil Daftar",
  "加载中": "Memuat",
  "您还未登录，请先登录": "Anda belum login, silakan login terlebih dahulu",
  "退出成功": "Berhasil keluar",
  "市价": "Harga Pasar",
  "挂单": "Pesanan Limit",
  "倍数": "Kelipatan",
  "止盈": "Hentikan Laba",
  "止损": "Hentikan Kerugian",
  "买入数量": "Jumlah Beli",
  "每手": "Per Lot",
  "预估手续费": "Perkiraan Biaya",
  "预估保证金": "Perkiraan Margin",
  "买入": "Beli",
  "卖出": "Jual",
  "您的订单已确认": "Pesanan Anda telah dikonfirmasi",
  "订单页面": "Halaman Pesanan",
  "盈亏": "Untung/Rugi",
  "当前保证金": "Margin Saat Ini",
  "订单详情": "Detail Pesanan",
  "保证金": "Margin",
  "平仓": "Tutup Posisi",
  "修改": "Ubah",
  "手数": "Ukuran Lot",
  "暂无数据": "Tidak ada data",
  "历史": "Riwayat",
  "价格": "Harga",
  "请输入价格": "Masukkan harga",
  "手": "Lot",
  "搜索结果": "Hasil Pencarian",
  "搜索产品": "Cari Produk",
  "持仓": "Posisi",
  "语言": "Bahasa",
  "审核中": "Dalam Tinjauan",
  "请上传证件照正/反面": "Unggah foto depan/belakang identitas",
  "上传成功": "Berhasil Diunggah",
  "上传失败": "Gagal Unggah",
  "提交成功": "Berhasil Dikirim",
  "删除": "Hapus",
  "删除成功": "Berhasil Dihapus",
  "提币": "Penarikan",
  "缺少参数或传值错误": "Parameter hilang atau kesalahan nilai",
  "操作成功": "Operasi Berhasil",
  "充值金额": "Jumlah Deposit",
  "类型": "Jenis",
  "状态": "Status",
  "时间": "Waktu",
  "单位": "Satuan",
  "最高价": "Harga Tertinggi",
  "最低价": "Harga Terendah",
  "开盘价": "Harga Pembukaan",
  "请注册或登录真实账号": "Silakan daftar atau login dengan akun asli",
  "没有更多了": "Tidak ada lagi",
  "提现金额": "Jumlah Penarikan",
  "审核通过": "Disetujui",
  "审核不通过": "Tidak Disetujui",
  "重新提交": "Kirim Ulang",
  "止盈价": "Harga Hentikan Laba",
  "止损价": "Harga Hentikan Kerugian",
  "总盈亏": "Total Untung/Rugi",
  "撤单": "Batalkan Pesanan"
}
