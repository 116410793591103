export default {
  "首页": "Ana Sayfa",
  "订单": "Sipariş",
  "新闻": "Haberler",
  "个人中心": "Kişisel Merkez",
  "模拟账户": "Demo Hesabı",
  "可用资产": "Kullanılabilir Varlık",
  "入金": "Para Yatırma",
  "出金": "Para Çekme",
  "账单详情": "Fatura Detayları",
  "重置模拟账户": "Demo Hesabını Sıfırla",
  "钱包": "Cüzdan",
  "实名认证": "Kimlik Doğrulama",
  "邀请好友": "Arkadaş Davet Et",
  "修改密码": "Şifreyi Değiştir",
  "投诉邮箱": "Şikayet E-posta Adresi",
  "其它": "Diğer",
  "公告": "Duyurular",
  "语言": "Dil",
  "是否退出登录": "Çıkış yapmak istediğinizden emin misiniz?",
  "复制": "Kopyala",
  "复制成功": "Başarıyla Kopyalandı",
  "发送验证码成功": "Doğrulama Kodu Gönderildi",
  "请输入密码": "Lütfen şifrenizi girin",
  "请再次输入密码": "Şifrenizi bir daha girin",
  "复制邀请链接": "Davet Linkini Kopyala",
  "上传身份": "Pasaport/Kimlik Belgesinin Ön ve Arka Yüzünü Yükleyin",
  "正面": "Ön Yüz",
  "上传正面": "Pasaport/Kimlik Belgesinin Ön Yüzünü Yükleyin",
  "反面": "Arka Yüz",
  "上传反面": "Pasaport/Kimlik Belgesinin Arka Yüzünü Yükleyin",
  "完善信息": "Lütfen Kişisel Bilgilerinizi Tamamlayın",
  "输入姓名": "Adınızı girin",
  "身份证号": "Pasaport/Kimlik Numarası",
  "输入身份证号": "Lütfen Pasaport/Kimlik Numarasını girin",
  "收款货币": "Alınacak Para Birimi",
  "银行名称": "Banka Adı",
  "收款人账户": "Alıcı Hesabı",
  "货币": "Para Birimi",
  "分店名称": "Şube Adı",
  "绑定银行卡": "Banka Kartı Bağla",
  "银行地址": "Banka Adresi",
  "账号名称（假名）": "Hesap Adı (Takma Ad)",
  "绑定数字货币地址": "Dijital Para Cüzdanı Adresi Bağla",
  "余额": "Bakiye",
  "联系客服": "Müşteri Hizmetleri ile İletişime Geç",
  "行情": "Piyasa",
  "其他": "Diğer",
  "设置": "Ayarlar",
  "收款人": "Alıcı",
  "添加": "Ekle",
  "确认": "Onayla",
  "取消": "İptal",
  "钱包地址": "Cüzdan Adresi",
  "请输入": "Lütfen girin",
  "退出登录": "Çıkış Yap",
  "充值数量": "Yatırılan Miktar",
  "提交": "Gönder",
  "银行卡": "Banka Kartı",
  "选择网络": "Ağ Seçin",
  "数字货币": "Dijital Para",
  "上传验证": "Doğrulama Yükle",
  "提币地址": "Çekim Adresi",
  "数量(USD)": "Miktar (USD)",
  "备注": "Açıklama",
  "手续费": "İşlem Ücreti",
  "预计到账数量": "Tahmini Hesaba Geçen Miktar",
  "暂无数据": "Veri Yok",
  "入金记录": "Para Yatırma Kaydı",
  "提币记录": "Para Çekme Kaydı",
  "验证码": "Doğrulama Kodu",
  "邀请码": "Davet Kodu",
  "提示": "İpucu",
  "请输入验证码": "Lütfen doğrulama kodunu girin",
  "姓名": "Ad",
  "邮箱登录": "E-posta ile Giriş Yap",
  "邮箱": "E-posta",
  "密码": "Şifre",
  "新用户": "Yeni Kullanıcı",
  "立即加入": "Hemen Katıl",
  "忘记密码": "Şifrenizi Mi Unuttunuz?",
  "登录": "Giriş Yap",
  "无需开户,立即创建模拟账号进行交易!": "Hesap açmanıza gerek yok, hemen demo hesabı oluşturun ve işlem yapmaya başlayın!",
  "电话": "Telefon",
  "再次输入密码": "Şifrenizi Tekrar Girin",
  "职业": "Meslek",
  "年收入": "Yıllık Gelir",
  "注册": "Kayıt Ol",
  "邮箱注册": "E-posta ile Kayıt Ol",
  "发送": "Gönder",
  "重置密码": "Şifreyi Sıfırla",
  "是否重置": "Şifreyi sıfırlamak istediğinizden emin misiniz?",
  "已重置": "Şifre sıfırlandı",
  "名称": "Ad",
  "注册成功": "Başarıyla Kayıt Oldunuz",
  "加载中": "Yükleniyor",
  "您还未登录，请先登录": "Henüz giriş yapmadınız, lütfen önce giriş yapın",
  "退出成功": "Başarıyla Çıkış Yapıldı",
  "市价": "Piyasa Fiyatı",
  "挂单": "Bekleyen Emir",
  "倍数": "Çarpan",
  "止盈": "Kar Al",
  "止损": "Zarar Durdur",
  "买入数量": "Alım Miktarı",
  "每手": "Her Kontrat",
  "预估手续费": "Tahmini İşlem Ücreti",
  "预估保证金": "Tahmini Teminat",
  "买入": "Al",
  "卖出": "Sat",
  "您的订单已确认": "Siparişiniz Onaylandı",
  "订单页面": "Sipariş Sayfası",
  "盈亏": "Kar/Zarar",
  "当前保证金": "Mevcut Teminat",
  "订单详情": "Sipariş Detayları",
  "保证金": "Teminat",
  "平仓": "Pozisyon Kapat",
  "修改": "Değiştir",
  "手数": "Sözleşme Sayısı",
  "暂无数据": "Veri Yok",
  "历史": "Geçmiş",
  "价格": "Fiyat",
  "请输入价格": "Lütfen fiyat girin",
  "手": "Kontrat",
  "搜索结果": "Arama Sonuçları",
  "搜索产品": "Ürün Ara",
  "持仓": "Açık Pozisyon",
  "语言": "Dil",
  "审核中": "İnceleniyor",
  "请上传证件照正/反面": "Lütfen kimlik belgenizin ön/arka yüzünü yükleyin",
  "上传成功": "Başarıyla Yüklendi",
  "上传失败": "Yükleme Başarısız",
  "提交成功": "Başarıyla Gönderildi",
  "删除": "Sil",
  "删除成功": "Başarıyla Silindi",
  "提币": "Para Çek",
  "缺少参数或传值错误": "Eksik parametre veya değer hatası",
  "操作成功": "İşlem Başarılı",
  "充值金额": "Yatırılan Tutar",
  "类型": "Tür",
  "状态": "Durum",
  "时间": "Zaman",
  "单位": "Birim",
  "最高价": "En Yüksek Fiyat",
  "最低价": "En Düşük Fiyat",
  "开盘价": "Açılış Fiyatı",
  "请注册或登录真实账号": "Lütfen gerçek hesabınızla kaydolun veya giriş yapın",
  "没有更多了": "Daha fazla veri yok",
  "提现金额": "Çekilen Tutar",
  "审核通过": "Onaylandı",
  "审核不通过": "Onaylanmadı",
  "重新提交": "Tekrar Gönder",
  "止盈价": "Kar Al Fiyatı",
  "止损价": "Zarar Durdur Fiyatı",
  "总盈亏": "Toplam Kar/Zarar",
  "撤单": "Emri İptal Et"
}
