export default {
  "首页": "Inicio",
  "订单": "Orden",
  "新闻": "Noticias",
  "个人中心": "Centro de Usuario",
  "模拟账户": "Cuenta Demo",
  "可用资产": "Activos Disponibles",
  "入金": "Depósito",
  "出金": "Retiro",
  "账单详情": "Detalles de Factura",
  "重置模拟账户": "Restablecer Cuenta Demo",
  "钱包": "Cartera",
  "实名认证": "Verificación de Identidad",
  "邀请好友": "Invitar Amigos",
  "修改密码": "Cambiar Contraseña",
  "投诉邮箱": "Correo de Quejas",
  "其它": "Otros",
  "公告": "Anuncio",
  "语言": "Idioma",
  "是否退出登录": "¿Desea cerrar sesión?",
  "复制": "Copiar",
  "复制成功": "Copia Exitosa",
  "发送验证码成功": "Código de Verificación Enviado",
  "请输入密码": "Ingrese Contraseña",
  "请再次输入密码": "Ingrese la Contraseña Nuevamente",
  "复制邀请链接": "Copiar Enlace de Invitación",
  "上传身份": "Subir Frente y Reverso del Pasaporte/ID",
  "正面": "Frente",
  "上传正面": "Subir Frente",
  "反面": "Reverso",
  "上传反面": "Subir Reverso",
  "完善信息": "Complete su Información",
  "输入姓名": "Ingrese su Nombre",
  "身份证号": "Número de ID/Pasaporte",
  "输入身份证号": "Ingrese su Número de ID/Pasaporte",
  "收款货币": "Moneda de Cobro",
  "银行名称": "Nombre del Banco",
  "收款人账户": "Cuenta del Beneficiario",
  "货币": "Moneda",
  "分店名称": "Nombre de la Sucursal",
  "绑定银行卡": "Vincular Tarjeta Bancaria",
  "银行地址": "Dirección del Banco",
  "账号名称（假名）": "Nombre de Cuenta (Alias)",
  "绑定数字货币地址": "Vincular Dirección de Criptomoneda",
  "余额": "Saldo",
  "联系客服": "Contactar Soporte",
  "行情": "Mercado",
  "其他": "Otros",
  "设置": "Configuración",
  "收款人": "Beneficiario",
  "添加": "Agregar",
  "确认": "Confirmar",
  "取消": "Cancelar",
  "钱包地址": "Dirección de la Cartera",
  "请输入": "Ingrese",
  "退出登录": "Cerrar Sesión",
  "充值数量": "Cantidad de Depósito",
  "提交": "Enviar",
  "银行卡": "Tarjeta Bancaria",
  "选择网络": "Seleccionar Red",
  "数字货币": "Criptomoneda",
  "上传验证": "Subir Verificación",
  "提币地址": "Dirección de Retiro",
  "数量(USD)": "Cantidad (USD)",
  "备注": "Notas",
  "手续费": "Comisión",
  "预计到账数量": "Cantidad Estimada a Recibir",
  "暂无数据": "No Hay Datos",
  "入金记录": "Registro de Depósitos",
  "提币记录": "Registro de Retiros",
  "验证码": "Código de Verificación",
  "邀请码": "Código de Invitación",
  "提示": "Recordatorio",
  "请输入验证码": "Ingrese el Código de Verificación",
  "姓名": "Nombre",
  "邮箱登录": "Inicio de Sesión por Correo",
  "邮箱": "Correo",
  "密码": "Contraseña",
  "新用户": "Usuario Nuevo",
  "立即加入": "Unirse Ahora",
  "忘记密码": "Olvidé mi Contraseña",
  "登录": "Iniciar Sesión",
  "无需开户,立即创建模拟账号进行交易!": "¡Sin Necesidad de Cuenta, Cree una Cuenta Demo para Comenzar a Operar!",
  "电话": "Teléfono",
  "再次输入密码": "Reingresar Contraseña",
  "职业": "Ocupación",
  "年收入": "Ingresos Anuales",
  "注册": "Registrarse",
  "邮箱注册": "Registro por Correo",
  "发送": "Enviar",
  "重置密码": "Restablecer Contraseña",
  "是否重置": "¿Restablecer?",
  "已重置": "Restablecido",
  "名称": "Nombre",
  "注册成功": "Registro Exitoso",
  "加载中": "Cargando",
  "您还未登录，请先登录": "No ha Iniciado Sesión, Inicie Sesión Primero",
  "退出成功": "Cierre de Sesión Exitoso",
  "市价": "Precio de Mercado",
  "挂单": "Orden Pendiente",
  "倍数": "Multiplicador",
  "止盈": "Toma de Ganancias",
  "止损": "Stop Loss",
  "买入数量": "Cantidad de Compra",
  "每手": "Por Lote",
  "预估手续费": "Comisión Estimada",
  "预估保证金": "Margen Estimado",
  "买入": "Comprar",
  "卖出": "Vender",
  "您的订单已确认": "Su Orden ha sido Confirmada",
  "订单页面": "Página de Orden",
  "盈亏": "Ganancia y Pérdida",
  "当前保证金": "Margen Actual",
  "订单详情": "Detalles de la Orden",
  "保证金": "Margen",
  "平仓": "Cerrar Posición",
  "修改": "Editar",
  "手数": "Tamaño de Lote",
  "暂无数据": "No Hay Datos",
  "历史": "Historial",
  "价格": "Precio",
  "请输入价格": "Ingrese el Precio",
  "手": "Lote",
  "搜索结果": "Resultados de Búsqueda",
  "搜索产品": "Buscar Producto",
  "持仓": "Posiciones",
  "语言": "Idioma",
  "审核中": "En Revisión",
  "请上传证件照正/反面": "Suba el Frente y Reverso del ID",
  "上传成功": "Subida Exitosa",
  "上传失败": "Error de Subida",
  "提交成功": "Enviado con Éxito",
  "删除": "Eliminar",
  "删除成功": "Eliminación Exitosa",
  "提币": "Retirar",
  "缺少参数或传值错误": "Falta Parámetro o Valor Incorrecto",
  "操作成功": "Operación Exitosa",
  "充值金额": "Monto de Depósito",
  "类型": "Tipo",
  "状态": "Estado",
  "时间": "Hora",
  "单位": "Unidad",
  "最高价": "Precio Máximo",
  "最低价": "Precio Mínimo",
  "开盘价": "Precio de Apertura",
  "请注册或登录真实账号": "Por Favor, Regístrese o Inicie Sesión en una Cuenta Real",
  "没有更多了": "No Hay Más Datos",
  "提现金额": "Monto de Retiro",
  "审核通过": "Aprobado",
  "审核不通过": "No Aprobado",
  "重新提交": "Reenviar",
  "止盈价": "Precio de Toma de Ganancias",
  "止损价": "Precio de Stop Loss",
  "总盈亏": "Ganancia y Pérdida Total",
  "撤单": "Cancelar Orden"
}
